import { useMemo, useState } from "react"
import styled from "styled-components"

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"

import { DeviceCommand } from "src/components/RemoteDebugService/minutWsProtocol"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { debug } from "src/utils/logger"

export function DeviceCommands({
  deviceDataUri,
  onDeviceCommand,
  loading,
}: {
  deviceDataUri: string
  onDeviceCommand: (c: DeviceCommand, args?: string) => void
  loading?: boolean
}) {
  const [args, setArgs] = useState("")

  function handleCommand() {
    if (!selectedCmd) return
    if (selectedCmd.id === DeviceCommand.RECOVERY) {
      alert("Not implemented")
      return
    }
    if (selectedCmd.id === DeviceCommand.ENROLL) {
      // Implementation suggestion:
      // 1. Set wifi conf
      // 2. get enroll token
      // 3. tell device to enroll
      // const home_id = args
      // const { data } = await minutApiHttpClient.post(
      //   `${API_DEFAULT}/applications/enroll`,
      //   { user_id, home_id }
      // )
      // const token = data?.enroll_token
      // onDeviceCommand(
      //   DeviceCommand.ENROLL,
      //   JSON.stringify({
      //     token,
      //     host: "point.staging.minut.com",
      //     port: 25500,
      //   })
      // )
      alert("Not implemented")
      return
    }
    if (selectedCmd.id === DeviceCommand.CON) {
      onDeviceCommand(DeviceCommand.LIGHT, "mfg_attention")
      onDeviceCommand(DeviceCommand.SOUND)
    } else {
      debug.log("Sending cmd", selectedCmd.id, args)
      onDeviceCommand(selectedCmd?.id, args)
    }
  }

  interface ICmd {
    id: DeviceCommand
    label: string
    argsLabel?: string
    argsPlaceholder?: string
    argsList?: { id: string; label: string }[]
  }
  const [selectedCmd, setSelectedCmd] = useState<ICmd | null>(null)
  const cmds: ICmd[] = useMemo(
    () => [
      {
        //
        id: DeviceCommand.CON,
        label: "Connect ( ͡° ͜ʖ ͡°)",
      },
      {
        //
        id: DeviceCommand.LIGHT,
        label: "Set light",
        argsLabel: "Light mode",
        argsPlaceholder: "identify|off|green|red|mfg_*",
        argsList: [
          { id: "off", label: "Off" },
          { id: "red", label: "Red" },
          { id: "green", label: "Green" },
          { id: "mfg_attention", label: "Attention" },
          { id: "mfg_running", label: "Running" },
          { id: "mfg_charging", label: "Charging" },
          { id: "mfg_waiting", label: "Waiting" },
          { id: "mfg_discharging", label: "Bright" },
          { id: "identify", label: "Identify" },
        ],
      },
      {
        //
        id: DeviceCommand.SOUND,
        label: "Play sound file",
      },
      {
        //
        id: DeviceCommand.LS,
        label: "List device files",
      },
      {
        //
        id: DeviceCommand.WLANSCAN,
        label: "Scan wifi",
      },
      {
        //
        id: DeviceCommand.GET_DEVICE_INFO,
        label: "Get device info",
      },
      {
        //
        id: DeviceCommand.APPLY_FOTA,
        label: "Apply FOTA",
        argsLabel: "Firmware version",
        argsPlaceholder: "E.g., 24063",
      },
      {
        //
        id: DeviceCommand.REBOOT,
        label: "Reboot device",
      },
    ],
    []
  )

  return (
    <CommandPanel>
      <FormControl variant="outlined">
        <InputLabel htmlFor="commands">Command</InputLabel>
        <Select
          disabled={!deviceDataUri || loading}
          value={selectedCmd?.id ?? ""}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
          onChange={(e: any) => {
            setArgs("")
            setSelectedCmd(
              // @ts-expect-error: noUncheckedIndexedAccess
              cmds.find((item) => item.id === e.target.value) || cmds[0]
            )
          }}
          label="Command"
          inputProps={{ name: "commands", id: "commands" }}
        >
          {cmds.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedCmd?.argsList && selectedCmd.argsLabel ? (
        <FormControl variant="outlined">
          <InputLabel htmlFor="args">Command</InputLabel>
          <Select
            disabled={!deviceDataUri || loading}
            value={args}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
            onChange={(e: any) => setArgs(e.target.value)}
            label={selectedCmd.argsLabel}
            inputProps={{ name: "args", id: "args" }}
          >
            {selectedCmd.argsList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : selectedCmd?.argsLabel ? (
        <TextField
          value={args}
          onChange={(e) => setArgs(e.target.value)}
          label={selectedCmd.argsLabel}
          placeholder={selectedCmd.argsPlaceholder}
          disabled={!selectedCmd.argsLabel}
        />
      ) : null}

      <MButtonLegacy
        disabled={!selectedCmd || !deviceDataUri || loading}
        onClick={() => handleCommand()}
      >
        Send
      </MButtonLegacy>
    </CommandPanel>
  )
}

const CommandPanel = styled.div`
  display: flex;
  gap: 0.5rem;

  > div {
    flex: 1 0 auto;
  }
`

export function AttachRemoteDebug({
  onSubmit,
}: {
  onSubmit: (uri: string) => void
}) {
  const [attachUri, setAttachUri] = useState("")

  return (
    <form
      style={{ display: "flex", gap: "0.5rem" }}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(attachUri)
      }}
    >
      <TextField
        label={"Connect to remote device"}
        placeholder={"Command URI"}
        value={attachUri}
        onChange={(e) => setAttachUri(e.target.value)}
      />

      <MButtonLegacy disabled={false} type="submit">
        Attach
      </MButtonLegacy>
    </form>
  )
}
